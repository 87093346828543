import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

import accolades from "../../assets/accolades.webp";
import attorneys from "../../assets/attorneys.webp";
import seminarPic from "../../assets/seminar-pic.webp";

import ApptScheduler from "../common/apptScheduler";
import EstateScheduler from "../common/seminarScheduler";
import Page from "../common/page";
import { Mobile } from "../common/responsive";

import { ReactComponent as ArrowRightIcon } from "../../assets/arrow-right-icon.svg";
import { ReactComponent as CalendarIcon } from "../../assets/calendar-icon.svg";
import { ReactComponent as PlayIcon } from "../../assets/play-icon.svg";
import { ReactComponent as VolumeIcon } from "../../assets/volume-icon.svg";

import "../../styles/components/pages/home.scss";

function Home() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
    ReactGA4.initialize("AW-10835803064");
    ReactGA4.send("pageview");
  }, []);

  const podcastTeaser = (
    <div className="podcast-teaser">
      <div className="content">
        <div className="title">
          <VolumeIcon />
          <p className="heading-font fw-bolder">
            Hear more from us on our Podcast:{" "}
            <span className="fst-italic">
              <nobr>Life, Death, & the Law</nobr>
            </span>
          </p>
        </div>
        <button
          className="btn-rounded"
          onClick={() => window.open("https://youtube.com/@DeasonGarnerHansen")}
        >
          <ArrowRightIcon />
          Listen Now
        </button>
      </div>
    </div>
  );

  const teaser = (
    <div className="teaser">
      <Mobile>
        <div className="mobile-image"></div>
      </Mobile>
      <div className="content-container">
        <div className="content-block">
          <EstateScheduler />
        </div>
      </div>
    </div>
  );

  const seminar = (
    <div className="content-container seminar">
      <div className="content-block">
        <h2 className="header align-self-center">Get Started</h2>
        <div className="sections mt-5">
          <div className="section">
            <p className="primary">
              Schedule your free consultation after viewing our Online Seminar
            </p>
            <img
              src={seminarPic}
              alt="Click to watch our free online seminar"
              className="mt-3"
              width="100%"
              onClick={() =>
                window.open(
                  "https://dghlaw.clickfunnels.com/auto-webinar-registration1588286121274"
                )
              }
            />
            <button
              className="mt-4 btn-filled-tertiary font-18 p-4"
              onClick={() =>
                window.open(
                  "https://dghlaw.clickfunnels.com/auto-webinar-registration1588286121274"
                )
              }
            >
              <PlayIcon />
              Watch Now
            </button>
          </div>
          <Mobile>
            <div className="border m-4"></div>
          </Mobile>
          <div className="section">
            <div className="appt-scheduler">
              <h3 className="title">How Can We Help You?</h3>
              <ApptScheduler />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const serviceTeaser = (
    <div className="content-container service-teaser">
      <div className="content-block">
        <div className="service">
          <h3>Trusts</h3>
          <p>
            Trusts ensure your wishes are carried out privately and without the
            probate process
          </p>
          <Link to="/estate-planning/living-trust">
            <button className="btn-filled-tertiary w-100">
              <ArrowRightIcon />
              Learn More
            </button>
          </Link>
        </div>
        <div className="service">
          <h3>Wills</h3>
          <p>
            Our attorneys can help draft a will that serves your estate planning
            goals and needs.
          </p>
          <Link to="/estate-planning/wills">
            <button className="btn-filled-tertiary w-100">
              <ArrowRightIcon />
              Learn More
            </button>
          </Link>
        </div>
        <div className="service">
          <h3>Probate</h3>
          <p>Our attorneys are here to help you through the probate process</p>
          <Link to="/estate-planning/probate">
            <button className="btn-filled-tertiary w-100">
              <ArrowRightIcon />
              Learn More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );

  const reviews = (
    <div className="content-container reviews">
      <div className="content-block">
        <h2 className="header">Don't Take Our Word For It...</h2>
        <div className="d-flex flex-row justify-content-around mt-5">
          <Carousel>
            <Carousel.Item>
              <div className="review">
                <p className="text">
                  "Deason Garner Law Firm was and is still nice to work with,
                  their firm is responsive and take action when needed! they are
                  our to go to when we are in a crunch and get the job done
                  well! a big special thank you to Janet Leon she is the BEST!!
                  :)"
                </p>
                <p className="author">Elissa A. - Google</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="review">
                <p className="text">
                  "Staff at the Deason Garner & Hansen Law Firm have been very
                  professional when working with them. I have mostly worked with
                  Ms. Janet L. and she is always knowledgeable, responds quickly
                  and very helpful. Thank you!"
                </p>
                <p className="author">Elizabeth C. - Google</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="review">
                <p className="text">
                  "Their knowledge is insurmountable! The staff is beyond caring
                  and dealing with Adam has helped make my parents trust so easy
                  to understand! I now have my own trust and recommend it to my
                  friends and family! Thank you!"
                </p>
                <p className="author">Chandra M. - Facebook</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="review">
                <p className="text">
                  "I would highly recommend them. They were wonderful, they
                  explained everything really well and my mom Vikki Armer was
                  very happy with there service. It gives her peace of mind
                  during these trying times with covid 19."
                </p>
                <p className="author">Patty A. - Facebook</p>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );

  const team = (
    <div className="content-container team">
      <div className="content-block">
        <h2 className="header align-self-center">Get to Know Our Team</h2>
        <div className="sections mt-5">
          <div className="section">
            <img src={attorneys} alt="Attorneys Shawn Garner and Adam Hansen" />
          </div>
          <Mobile>
            <div className="border my-5 mx-4"></div>
          </Mobile>
          <div className="section">
            <div>
              <p className="font-18 text-center">
                Our attorneys and team are dedicated to providing you with the
                most accurate and up-to-date practices and options in estate
                planning and elder care law. We are committed to helping you
                prepare for the future and ensuring your wishes are carried out.
                Call today to make your appointment!
              </p>
              <button
                className="mt-5 btn-filled-secondary font-18 p-4 mx-auto"
                onClick={() =>
                  window.open(
                    "https://app.squarespacescheduling.com/schedule.php?owner=22156098"
                  )
                }
              >
                <CalendarIcon />
                Book an Appointment
              </button>
              <img
                src={accolades}
                alt="Best probate attorneys in Yuma"
                className="mt-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const officeTour = (
    <div style={{ lineHeight: "0" }}>
      <iframe
        title="Deason Garner & Hansen Law Firm Map"
        src="https://my.matterport.com/show/?m=vXBLU83ddiM"
        width="100%"
        height="600px"
        allowFullScreen=""
        loading="lazy"
      />
    </div>
  );

  const googleMap = (
    <div style={{ lineHeight: "0" }}>
      <iframe
        title="Deason Garner & Hansen Law Firm Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3358.578555886962!2d-114.53176268431359!3d32.67065749670713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d658adaf9e08b5%3A0x62a4c6174e1015f1!2sDeason%20Garner%20%26%20Hansen%20Law%20Firm!5e0!3m2!1sen!2sus!4v1659742612308!5m2!1sen!2sus"
        width="100%"
        height="600px"
        allowFullScreen=""
        loading="lazy"
      />
    </div>
  );

  return (
    <Page
      showBanner
      selectedTab="home"
      className="home"
      title="Deason Garner & Hansen Law Firm | Yuma, AZ | Trusts | Wills | Probate | Power of Attorney"
      description="Are your affairs in order? If not, we're here to help! Call now to schedule an appointment."
    >
      {podcastTeaser}
      {teaser}
      {seminar}
      {serviceTeaser}
      {reviews}
      {team}
      {officeTour}
      {googleMap}
    </Page>
  );
}

export default Home;
